<template>
    <section>
        <div class="content">
            <h2 class="has-text-primary-dark">Gestión de procesos</h2>
        </div>
        <div class="card">
            <div class="card-content">
                <b-field label="Tipo de Proceso" label-position="on-border">
                    <b-select
                        v-model="tipoProcesoSlug"
                        :loading="isLoadingTiposProcesos"
                        @input="fetchProcesos(tipoProcesoSlug)"
                    >
                        <option
                            v-for="tipoProceso in tiposProcesos"
                            :value="tipoProceso.slug"
                            :key="tipoProceso.id"
                        >
                            {{ tipoProceso.descripcion }}
                        </option>
                    </b-select>
                </b-field>
                <b-table
                    :data="procesos"
                    :loading="isLoadingProcesos"
                    :paginated="true"
                >
                    <b-table-column
                        field="proceso"
                        label="Proceso"
                        v-slot="props"
                    >
                        {{ props.row.tipo.descripcion }}
                    </b-table-column>
                    <b-table-column
                        field="usuario"
                        label="Usuario"
                        v-slot="props"
                    >
                        <b>{{
                            props.row.perfil.trabajador.usuario.username
                        }}</b>
                        - {{ props.row.perfil | nombreCompletoTrabajador }}
                    </b-table-column>
                    <b-table-column
                        field="creado"
                        label="Creado"
                        v-slot="props"
                        sortable
                    >
                        {{ props.row.created_at | moment }}
                    </b-table-column>
                    <b-table-column
                        field="finalizado"
                        label="Finalizado"
                        v-slot="props"
                        sortable
                    >
                        {{ props.row.finished_at | moment }}
                    </b-table-column>
                    <b-table-column
                        field="estatus"
                        label="Estatus"
                        v-slot="props"
                    >
                        <b-tag v-if="props.row.activo" type="is-info">
                            En proceso
                        </b-tag>
                        <b-tag v-else> Finalizado </b-tag>
                    </b-table-column>
                    <b-table-column v-slot="props">
                        <b-tooltip
                            v-if="!props.row.activo"
                            label="Ver Detalle"
                            type="is-dark"
                        >
                            <a @click.prevent="viewDetail(props.row)">
                                <b-icon icon="eye" />
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">No hay registros</div>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal v-model="isActive">
            <div class="card">
                <header class="card-header">
                    <p class="card-header-title">Detalle del proceso</p>
                </header>
                <div class="card-content">
                    <h3 class="has-text-weight-bold has-text-primary-dark">
                        {{ proceso.respuesta.message }}
                    </h3>
                    <p>
                        <b>Status:</b>
                        <b-tag
                            :type="`is-${proceso.respuesta.overview.status}`"
                            >{{
                                proceso.respuesta.overview.status.toUpperCase()
                            }}</b-tag
                        >
                    </p>
                    <p>
                        <b>Total de registros enviados:</b>
                        {{ proceso.respuesta.overview.total }}
                    </p>
                    <br />
                    <b-button
                        icon-left="download"
                        @click="handleExportResultados(proceso.id)"
                    >
                        Exportar Resultados
                    </b-button>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            tipoProcesoSlug: '',
            isActive: false,
            proceso: {
                respuesta: {
                    message: '',
                    overview: {
                        total: 0,
                        status: '',
                    },
                    results: {
                        success: [],
                        errors: [],
                    },
                },
            },
        };
    },
    computed: {
        procesos() {
            return this.$store.state.procesos.data;
        },
        tiposProcesos() {
            return [
                { id: 0, slug: '', descripcion: 'TODOS' },
                ...this.$store.state.tiposProcesos.data,
            ];
        },
        isLoadingProcesos() {
            return this.$store.state.procesos.isLoading;
        },
        isLoadingTiposProcesos() {
            return this.$store.state.tiposProcesos.isLoading;
        },
    },
    async mounted() {
        await Promise.all([this.fetchProcesos(), this.fetchTiposProcesos()]);
    },
    methods: {
        async fetchProcesos(tipoProcesoSlug = null) {
            const res = await this.$store.dispatch(
                'procesos/get',
                tipoProcesoSlug
            );
        },
        async fetchTiposProcesos() {
            const res = await this.$store.dispatch('tiposProcesos/get');
        },
        viewDetail(proceso) {
            this.isActive = true;
            this.proceso = proceso;
        },
        async handleExportResultados(proceso_id) {
            await this.$store.dispatch('procesos/exportResultados', proceso_id);
        },
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).format('DD/MM/YYYY hh:mm:ss') : '-';
        },
        nombreCompletoTrabajador: function (perfil) {
            return `${perfil.trabajador.apellido_paterno} ${perfil.trabajador.apellido_materno} ${perfil.trabajador.nombre}`;
        },
    },
};
</script>

<style>
</style>
